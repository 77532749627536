import React from 'react';
import {
  Grid,
  Column,
  Theme,
  Tile,
  Link
} from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import SbsdHeader from '../../components/SBSDHeader/SBSDHeader';
import factoryService from '../../services/factory-service';
import utilService from '../../services/util-service';
import { useEffect, useState } from 'react';

const LandingPage = () => {
  const [technology, setTechnology] = useState(null);

  useEffect(() => {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);

    const getTech = async () => {
      const val = await factoryService.getAllTechnology();
      if (typeof val.data !== 'undefined' && val.data !== null) { let tch = val.data.filter(t => t.VISIBILITY === true); setTechnology(tch); };
    }

    if (factoryService.getTechCode() !== false) {
      getTech();
    } else {
      utilService.setOtherPageAccessible(false);
      window.location.href = '/a2mt/email-auth';
    }
  }, []);

  useEffect(() => { 
    console.log('technology', technology); 
    if(technology !== null) {
      sessionStorage.setItem('techList', JSON.stringify(technology));
      if(sessionStorage.getItem('userEm') !== 'undefined' && sessionStorage.getItem('userEm') !== null) {
        factoryService.getJWT(sessionStorage.getItem('userEm'));
        if (sessionStorage.getItem('cardRedirect') != null) {
          utilService.setOtherPageAccessible(true);
          window.location.href = sessionStorage.getItem('cardRedirect');
          sessionStorage.removeItem('cardRedirect');
        }
      }
    }
  }, [technology]);

  const handleRedirect = (event, data) => {
    utilService.setOtherPageAccessible(true);
    console.log('redirect',event,data);
    window.location = "/a2mt/downloads/" + data.split(' ').join('_').toLowerCase();
  }

  return (
    <div>
      <SbsdHeader></SbsdHeader>
      <Theme theme="g10">
        <Grid className="landing-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <h1 className="landing-page__heading">
              Explore all technologies
            </h1>
            <p className="landing-page__p">
              Complement your learning pathway with top-tier software across a range <br />
              of technologies. From Artificial Intelligence to engineering, access a <br />
              vast library of software to build expertise in new fields.
            </p>
            <p className="landing-page__line">&nbsp;</p>
          </Column>

          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <h1 className="landing-page__subheading">
              All technologies
            </h1>
          </Column>

          {technology !== null && technology.map((v, idx) => {
            return (
              <Column sm={4} md={4} lg={idx % 3 === 0 ? { span: 4, offset: 4 } : { span: 4 }} className="landing-page__gapping" onClick={e=>handleRedirect(e,v.NAME)}>
                <img
                  className="landing-page__illo"
                  src={v.IMG_URL}
                  alt={v.NAME}
                />
                <Tile id={'tile-'+idx}>
                  <h4>{v.NAME}</h4>
                  <br />
                  <br />
                  <Link style={{ float: "right" }} renderIcon={() => <ArrowRight aria-label="Link" />}></Link>
                  <br />
                </Tile>
              </Column>
            )
          })}
        </Grid>
      </Theme>
    </div>
  );
};

export default LandingPage;
